enum OrderSourceTypeCode {
  Unknown = -1,

  Miva = 0,

  Ebay = 1,

  Yahoo = 2,

  ShopSite = 3,

  MarketplaceAdvisor = 4,

  osCommerce = 5,

  ProStores = 6,

  ChannelAdvisor = 7,

  Infopia = 8,

  CreLoaded = 9,

  Amazon = 10,

  XCart = 11,

  OrderMotion = 12,

  ZenCart = 13,

  VirtueMart = 16,

  ClickCartPro = 17,

  PayPal = 18,

  Volusion = 19,

  NetworkSolutions = 20,

  Magento = 21,

  OrderDynamics = 22,

  SellerVantage = 23,

  WebShopManager = 24,

  AmeriCommerce = 25,

  CommerceInterface = 26,

  SearchFit = 27,

  GenericModule = 28,

  ThreeDCart = 29,

  BigCommerce = 30,

  GenericFile = 31,

  Shopify = 32,

  Etsy = 33,

  NeweggMarketplace = 34,

  BuyDotCom = 35,

  Sears = 36,

  SolidCommerce = 37,

  Brightpearl = 38,

  OrderDesk = 39,

  Cart66Lite = 40,

  Cart66Pro = 41,

  Shopp = 42,

  Shopperpress = 43,

  WPeCommerce = 44,

  Jigoshop = 45,

  WooCommerce = 46,

  ChannelSale = 47,

  Fortune3 = 48,

  LiveSite = 49,

  SureDone = 50,

  Zenventory = 51,

  nopCommerce = 52,

  LimeLightCRM = 53,

  OpenCart = 54,

  SellerExpress = 55,

  PowersportsSupport = 56,

  CloudConversion = 57,

  CsCart = 58,

  PrestaShop = 59,

  LoadedCommerce = 60,

  Choxi = 61,

  Groupon = 62,

  StageBloc = 63,

  RevolutionParts = 64,

  InstaStore = 65,

  OrderBot = 66,

  OpenSky = 67,

  LemonStand = 68,

  SparkPay = 69,

  Odbc = 70,

  Amosoft = 71,

  SellerCloud = 73,

  InfiPlex = 74,

  Walmart = 75,

  SellerActive = 76,

  GeekSeller = 78,

  Jet = 79,

  Manual = 80,

  Overstock = 82,

  CommerceV3 = 83,

  Zentail = 84,

  Bonanza = 85,

  Rakuten = 86,

  Api = 87,

  BrightpearlHub = 88,

  WalmartHub = 89,

  ChannelAdvisorHub = 90,

  VolusionHub = 91,

  GrouponHub = 92,

  ShipWorksIntegrations = 93,
}

export default OrderSourceTypeCode;
